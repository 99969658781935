import {
  Box,
  Button,
  Dialog,
  FormControl,
  Menu,
  MenuItem,
  Modal,
  Select,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { BiLoaderCircle } from 'react-icons/bi';
import { LZView, ObjectView, TableView } from 'components/Avaliations';
import { SelectableButton } from 'components/BullSearch';
import { Hr, Img, Space } from 'components/common';
import { objectFilter, objectIs } from 'object-fn-ts/lib';
import { actions, Store } from 'rdx';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bull, CommonProofEC, CommonProofLZ } from 'types';
import utils from 'utils';
import styles from './BullDetails.styles';
import Measurements from './Measurements';
import Pedigree from './Pedigree';
import GenericTableCenterChart from './Proof/GenericTableCenterChart';
import LEIndexes from './Proof/LEIndexes';
import TableCenterChart from './Proof/TableCenterChart';

import TableLeftChart from './Proof/TableLeftChart';
import StampList from './StampList';
import TextInfo from './TextInfo';
import React from 'react';

type Props = {
  bull: Bull;
};

function hasCarcaçaAvaliation(arr: { [k: string]: any }[]) {
  return arr.some((o) =>
    Object.entries(o)[0][0].toLowerCase().includes('carcaça')
  );
}

function maxStrLen(s: string, max = 0) {
  return s.length > max ? s.slice(0, max - 3) + '...' : s;
}
const BullDetails: FC<Props> = ({ bull }) => {
  const dispatch = useDispatch();
  const items = useSelector((store: Store) => store.items);
  const targetIndex = items.bulls.findIndex((b) => b.CRVcode === bull.CRVcode);
  const selected = items.selectedBulls.includes(targetIndex);
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [ videoModalOpen, setVideoModalOpen ] = useState(false);
  const [ selectedProof, setSelectedProof ] = useState<string>(
    Array.isArray(bull.proof) && bull.proof.length > 0
      ? (() => {
        const name1 = Object.entries(bull.proof[0])[0][0];
        const name2 = (Object.entries(bull.proof[0])[1] ?? '')[0];
        if (
          name1.toLowerCase().includes('carcaça') &&
            name2 !== null &&
            name2 !== undefined &&
            name2 !== ''
        )
          return name2;
        return name1;
      })()
      : ' '
  );
  const [ hasCarcaça, setHasCarcaça ] = useState<boolean>(false);
  const [ carcaçaName, setCarcaçaName ] = useState('');
  const [ selectedProofData, setSelectedProofData ] = useState<any>([]);
  const [ downloadPdfButtonContent, setDownloadPdfButtonContent ] = useState<
    string | JSX.Element
  >('Baixar PDF');
  const proofObject = objectFilter(
    bull.proof[0] || {},
    (key) => !String(key).toLowerCase().includes('carcaça')
  );

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPDF = (language: string) => {
    handleClose();

    if (language == null || language == '' || language == 'pt') {
      language = '';
    } else {
      language = '_' + language;
    }

    const url = `${process.env.REACT_APP_API}/bull/downloadpdf/${bull.warName}.pdf?url=${bull.pdf?.replace('.pdf', language + '.pdf')}`;
    window.open(url, '_blank');
  };
  
  useEffect(() => {
    try {
      const segment = utils.segmentFromBullOrBreed(bull);
      if (segment) {
        setHasCarcaça(hasCarcaçaAvaliation(bull.proof));
        const carcaçaAvaliation = bull.proof.find((p) =>
          Object.keys(p)[0].toLowerCase().includes('carcaça')
        );
        if (carcaçaAvaliation) {
          const carcaçaAvaliationObject = objectFilter(
            carcaçaAvaliation,
            (key) => String(key).toLowerCase().includes('carcaça')
          );
          const avaliationName = Object.keys(carcaçaAvaliationObject)[0];
          setCarcaçaName(avaliationName);
        }
      }
      if (
        selectedProof in proofObject &&
        !selectedProof.toLowerCase().includes('carcaça')
      ) {
        //TODO: corrigir caso dê problema
        if (segment) {
          const proofBuilder =
            utils.proofBuilders[
              `build${segment.toUpperCase()}` as keyof typeof utils.proofBuilders
            ];
          const data = proofBuilder(proofObject, selectedProof);
          setSelectedProofData(null);
          setTimeout(() => setSelectedProofData(data), 10);
        }
      } else {
        const carcaçaAvaliation = bull.proof.find((p) =>
          Object.keys(p)[0].toLowerCase().includes('carcaça')
        );
        if (carcaçaAvaliation) {
          const carcaçaAvaliationObject = objectFilter(
            carcaçaAvaliation,
            (key) => String(key).toLowerCase().includes('carcaça')
          );

          const carcaçaKey = Object.keys(carcaçaAvaliation)[0];

          const obj: any = {};

          for (
            let i = 0;
            i < carcaçaAvaliation[carcaçaKey]?.table.length;
            i++
          ) {
            obj[carcaçaAvaliation[carcaçaKey]?.table[i].name] =
              carcaçaAvaliation[carcaçaKey]?.table[i].values[0];
          }
          setSelectedProofData(obj);
        }
      }
    } catch (err) {
      console.log('RENDERING ERROR', { err });
      setSelectedProofData(null);
    }
  }, [ selectedProof, bull.proof ]);

  useEffect(() => {
    window.location.replace('crv-app://search/' + bull.CRVcode);
  }, []);


  const regex = /(?:v=([^&]+))|(?:youtu.be\/([^?]+))/;
  const videoId = regex.exec(bull?.youtube || '')?.[1] || regex.exec(bull?.youtube|| '')?.[2];


  {/* leite-zebu component rendering */}
  const renderLZ = () => {
    if (selectedProof !== ' ' && selectedProofData !== null && utils.segmentFromBullOrBreed(bull) === 'zl') {
      if (selectedProof.toLowerCase() === 'gir leiteiro') {
        let qntRenderElement : JSX.Element | null = null;
        let profileRenderElement : JSX.Element | null = null;
        selectedProofData.forEach((proof: any) => {
          if (proof.name.toLowerCase().includes('qt')) {
            qntRenderElement = <p>Qt. Filhas: {proof.values}</p>;
          }
          else if (proof.name.toLowerCase().includes('perfil') && proof.values[0] && typeof proof.values[0] === 'object') {
            const proofProfile: any = {};
            const regex = /\s\d+$/;
            Object.entries(proof.values[0]).forEach(([ key, value ]) => {
              proofProfile[key.replace(regex, '')] = {
                value: value,
                base: 0,
                max: 3,
                min: -3,
                middle: true
              };
            });
            profileRenderElement = <GenericTableCenterChart data={proofProfile} title={'PERFIL LINEAR'} key={'PERFIL LINEAR'} />;
          }
        });
        return (
          <div>
            {profileRenderElement}
            {qntRenderElement}
          </div>
        );
      } else {  
        return <LZView data={proofObject[selectedProof].table} />;
      }
    }
    return null;
  };

  return (
    <Stack
      {...utils.stack.flex.col('center', 'flex-start')}
      spacing={gtMd ? 4 : 2}
      sx={styles.container}
    >
      <Stack
        {...utils.stack.flex.row('space-between', 'flex-start')}
        sx={utils.sx.fw}
      >
        <Stack
          {...utils.stack.flex.col('flex-start', 'flex-start')}
          spacing={1}
        >
          <Typography variant="h2" translate="no">
            {bull?.warName != '-' ? bull?.warName : bull.name}
          </Typography>
          <Typography>{bull?.registry || 'S/R'}</Typography>
        </Stack>
        <Stack {...utils.stack.flex.col('flex-end', 'flex-start')} spacing={2}>
          {'ecommerce' in bull &&
            typeof bull.ecommerce === 'string' &&
            bull.ecommerce !== '-' && (
            <Button
              onClick={() => window.open(bull.ecommerce, '_blank')}
              sx={{ minWidth: '144px' }}
            >
                Comprar
            </Button>
          )}
          {'pdf' in bull &&
            typeof bull.pdf === 'string' &&
            !bull.pdf.includes('%2F-') && (
            <Button
              onClick={handleClick}
              sx={{
                height: '37.5px',
                overflow: 'hidden',
                minWidth: '144px',
                boderRadius: '2px',
                ':hover': {
                  background: 'rgba(220, 234, 246, 0.5);',
                },
                lineHeight: '1.5',
              }}
              variant="outlined"
            >
              <Stack
                {...utils.stack.flex.row('center', 'center')}
                spacing={1}
              >
                {downloadPdfButtonContent === 'Baixar PDF' ? (
                  <Img url="/icons/cloud-download.svg" />
                ) : null}

                <Typography
                  sx={(theme) => ({
                    fontSize: '0.875rem !important',
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                  })}
                >
                  {downloadPdfButtonContent}
                </Typography>
              </Stack>
            </Button>
          )}
        </Stack>
      </Stack>

      <Menu
        id="lock-menu"
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }} 
        anchorEl={anchorEl}
        open={open}      
        onClose={handleClose}
        PaperProps={{  
          style: {  
            width: 144,  
          },  
        }}
      >
        <MenuItem
          onClick={() => openPDF('pt')}
        >
          Português
        </MenuItem>
        <MenuItem
          key={1}
          onClick={() => openPDF('en')}
        >
          English
        </MenuItem>
        <MenuItem
          key={1}
          onClick={() => openPDF('es')}
        >
          Español
        </MenuItem>
      </Menu>

      <Stack
        {...utils.stack.flex.row('flex-start', 'flex-start')}
        spacing={4}
        sx={utils.sx.fw}
      >
        <Stack
          {...utils.stack.flex.col('center', 'flex-start')}
          spacing={3}
          sx={styles.leftSubContainer}
        >
          <Stack
            {...utils.stack.flex.col('center', 'center')}
            sx={styles.mainImgWrapper}
          >
            <Img
              sx={{ width: '100%', aspectRatio: '4/3', objectFit: 'cover' }}
              url={`https://firebasestorage.googleapis.com/v0/b/\
              crv-lagoa.appspot.com/o/\
              ${utils
      .segmentTranslator(
        utils.segmentFromBullOrBreed(bull),
        'pathlike'
      )
      .replace('-', '_')}\
              %2Fbulls%2F${bull.CRVcode}.jpg?alt=media`.replace(/\s/g, '')}
              fallbackImg="/imgs/default.png"
            />
            <Space size={2} />
            <SelectableButton
              selected={selected}
              onClick={() =>
                dispatch(
                  actions.items[selected ? 'unselectBull' : 'selectBull'](
                    targetIndex
                  )
                )
              }
            />
            <Space size={2} />
          </Stack>
          {gtMd && (
            <Stack
              {...utils.stack.flex.row('flex-start', 'center')}
              spacing={1}
              sx={{ width: '100%', overflowX: 'auto' }}
            >
              {bull?.youtube && <Tooltip title={''} arrow>
                <Stack>
                  <Img
                    sx={{
                      width: '120px',
                      aspectRatio: '3/2',
                      cursor: 'pointer',
                    }}
                    url={
                      `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`
                    }
                    fallbackImg="/imgs/default.png"
                    rmIfFail
                    onClick={() => {
                      setVideoModalOpen(true);
                    }}
                  />

                  <Typography
                    sx={(t) => ({
                      width: '100%',
                      background: t.palette.primary.main,
                      color: 'white',
                      fontSize: '10px !important',
                    })}
                  >
                    Video
                  </Typography>
                </Stack>
              </Tooltip>}

              {bull.images.slice(1).map((img) => {
                return (
                  <Tooltip
                    title={img.photoCaption ?? ''}
                    arrow
                    key={Math.random()}
                  >
                    <Stack>
                      <Img
                        sx={{
                          width: '120px',
                          aspectRatio: '3/2',
                          cursor: 'pointer',
                        }}
                        url={img.filepath}
                        fallbackImg="/imgs/default.png"
                        rmIfFail
                        onClick={() => {
                          dispatch(actions.modal.setSIB_URL(img.filepath));
                          dispatch(actions.modal.setSIBOpen(true));
                          dispatch(
                            actions.modal.setSIBSubtitle(
                              (img.photoCaption || '').trim()
                            )
                          );
                        }}
                      />
                      {img.photoCaption &&
                        img.photoCaption.trim() &&
                        img.photoCaption.trim() !== '-' && (
                        <Typography
                          sx={(t) => ({
                            width: '100%',
                            background: t.palette.primary.main,
                            color: 'white',
                            fontSize: '10px !important',
                          })}
                        >
                          {maxStrLen(img.photoCaption.trim(), 25)}
                        </Typography>
                      )}
                    </Stack>
                  </Tooltip>
                );
              })}
            </Stack>
          )}
        </Stack>

        {gtMd && (
          <Stack
            {...utils.stack.flex.col('flex-start', 'flex-start')}
            sx={{ width: '50%', maxWidth: '50%' }}
          >
            <StampList
              list={bull.stamps}
              segment={utils.segmentFromBullOrBreed(bull)}
            />
            <TextInfo bull={bull} />
          </Stack>
        )}
      </Stack>

      {!gtMd && (
        <Stack
          {...utils.stack.flex.row('flex-start', 'center')}
          spacing={1}
          sx={{ width: '100%', overflowX: 'auto' }}
        >
          <Tooltip title={''} arrow>
            <Stack>
              <Img
                sx={{
                  width: '120px',
                  aspectRatio: '3/2',
                  cursor: 'pointer',
                }}
                url={'https://i3.ytimg.com/vi/iHkGoJ0w_VU/maxresdefault.jpg'}
                fallbackImg="/imgs/default.png"
                rmIfFail
                onClick={() => {
                  setVideoModalOpen(true);
                }}
              />

              <Typography
                sx={(t) => ({
                  width: '100%',
                  background: t.palette.primary.main,
                  color: 'white',
                  fontSize: '10px !important',
                })}
              >
                Video
              </Typography>
            </Stack>
          </Tooltip>
          {bull.images.slice(1).map((img, i, arr) => {
            return (
              <Tooltip title="">
                <Stack>
                  <Img
                    sx={{ width: '120px', aspectRatio: '3/2' }}
                    url={img.filepath}
                    fallbackImg="/imgs/default.png"
                    rmIfFail
                    onClick={() => {
                      dispatch(actions.modal.setSIB_URL(img.filepath));
                      dispatch(actions.modal.setSIBOpen(true));
                      dispatch(
                        actions.modal.setSIBSubtitle(
                          (img.photoCaption || '').trim()
                        )
                      );
                    }}
                  />
                  {img.photoCaption &&
                    img.photoCaption.trim() &&
                    img.photoCaption.trim() !== '-' && (
                    <Typography
                      sx={(t) => ({
                        width: '100%',
                        background: t.palette.primary.main,
                        color: 'white',
                        fontSize: '10px !important',
                      })}
                    >
                      {maxStrLen(img.photoCaption.trim(), 25)}
                    </Typography>
                  )}
                </Stack>
              </Tooltip>
            );
          })}
        </Stack>
      )}

      {!gtMd && (
        <StampList
          list={bull.stamps}
          segment={utils.segmentFromBullOrBreed(bull)}
        />
      )}

      <Space size={1} />

      {!gtMd && <TextInfo bull={bull} />}

      <Space size={1} />
      <Hr />
      <Space size={1} />

      <Dialog
        onClose={() => setVideoModalOpen(false)}
        fullWidth={true}
        open={videoModalOpen}
      >
        <iframe
          style={{
            position: 'relative',
            width: '100%',
            aspectRatio: '3/2',
            borderWidth: '0px',
          }}
          src={'https://youtube.com/embed/' + videoId}
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </Dialog>

      <Stack
        {...utils.stack.flex.col('flex-start', 'center')}
        spacing={2}
        sx={utils.sx.fw}
      >
        <Typography> Seleção de avaliação </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedProof}
            onChange={(e) => {
              setSelectedProof(e.target.value);
            }}
          >
            {bull.proof.length === 0 && (
              <MenuItem disabled value={'s/r'}>
                {' '}
                Nenhuma avaliação encontrada{' '}
              </MenuItem>
            )}
            {hasCarcaça && (
              <MenuItem value={carcaçaName}>{carcaçaName}</MenuItem>
            )}
            {Object.entries(proofObject).map(([ key ]) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {/* carcaça - corte europeu */}
      {hasCarcaça &&
        selectedProof !== ' ' &&
        carcaçaName === selectedProof &&
        utils.segmentFromBullOrBreed(bull) === 'ec' &&
        selectedProofData !== null && (
        <ObjectView data={selectedProofData} title={carcaçaName} />
      )}

      {/* corte europeu */}
      {selectedProof !== ' ' &&
        selectedProofData !== null &&
        carcaçaName !== selectedProof &&
        utils.segmentFromBullOrBreed(bull) === 'ec' &&
        Array.isArray(selectedProofData) && (
        <TableView data={selectedProofData} />
      )}
      {selectedProof !== ' ' &&
        selectedProofData !== null &&
        utils.segmentFromBullOrBreed(bull) === 'ec' &&
        objectIs(selectedProofData) &&
        carcaçaName === '' && <ObjectView data={selectedProofData} />}

      {/* leite europeu */}
      {selectedProof !== ' ' &&
        selectedProofData !== null &&
        utils.segmentFromBullOrBreed(bull) === 'el' && (
        <Stack
          {...utils.stack.flex.col('flex-start', 'flex-start')}
          spacing={3}
          sx={{
            width: '100%',
            maxWidth: '480px',
            overflowX: 'auto',
            overflowY: 'hidden',
          }}
        >
          <LEIndexes selectedProofData={selectedProofData} />

          <Box
            sx={{
              width: '100%',
              maxWidth: '450px',
              minWidth: '340px',
              borderRadius: '4px',
              minHeight: '100px',
              marginTop: '44px !important',
            }}
          >
            <Stack
              {...utils.stack.flex.row('flex-start', 'center')}
              spacing={4}
            >
              <Img
                url="/icons/el-efc.svg"
                sx={{ width: '60px', height: '60px' }}
              />
              <Typography variant="h3" sx={{ color: '#014380' }}>
                {'EFICIÊNCIA'}
                {(selectedProofData as CommonProofEC)?.EFICIÊNCIA?.[
                  'Eficiência'
                ]?.value &&
                    ` +${
                      (selectedProofData as CommonProofEC).EFICIÊNCIA[
                        'Eficiência'
                      ].value
                    }`}
              </Typography>
            </Stack>

            <TableCenterChart
              selectedProofData={selectedProofData}
              color="#014380"
              objName="EFICIÊNCIA"
            />

            <Stack
              {...utils.stack.flex.row('flex-start', 'center')}
              spacing={4}
            >
              <Img
                url="/icons/el-sau.svg"
                sx={{ width: '60px', height: '60px' }}
              />
              <Typography variant="h3" sx={{ color: '#98002E' }}>
                {'SAÚDE'}
                {(selectedProofData as CommonProofEC)?.SAÚDE?.['Saúde']
                  ?.value &&
                    ` +${
                      (selectedProofData as CommonProofEC).SAÚDE['Saúde'].value
                    }`}
              </Typography>
            </Stack>

            <TableCenterChart
              selectedProofData={selectedProofData}
              color="#98002E"
              objName="SAÚDE"
            />
          </Box>
          {Object.entries(
            objectFilter(selectedProofData || {}, (key) =>
              [ 'CONFORMAÇÃO', 'PERFIL LINEAR', 'MANEJO/SANIDADE' ].includes(
                key.toString()
              )
            )
          ).map(([ title, data ]) => (
            <GenericTableCenterChart data={data} title={title} key={title} />
          ))}
        </Stack>
      )}

      {/* leite-zebu  */}
      {renderLZ()}

      {/* corte-zebu */}
      {hasCarcaça &&
        selectedProof !== ' ' &&
        carcaçaName === selectedProof &&
        utils.segmentFromBullOrBreed(bull) === 'zc' &&
        selectedProofData !== null && (
        <ObjectView data={selectedProofData} title={carcaçaName} />
      )}
      {selectedProof !== ' ' &&
        selectedProofData !== null &&
        carcaçaName !== selectedProof &&
        utils.segmentFromBullOrBreed(bull) === 'zc' && (
        <TableView
          data={selectedProofData.table as any}
          comp={(() => {
            const comp = objectFilter(
              selectedProofData,
              (_, val) => objectIs(val) && 'REB' in val && 'FILHOS' in val
            );
            return {
              cz: Object.entries(comp).map(([ key, val ]) => ({
                name: key,
                val,
              })),
            };
          })()}
        />
      )}

      {selectedProofData === null && (
        <Stack
          {...utils.stack.flex.col('center', 'center')}
          sx={utils.sx.container}
        >
          <Typography>Método de visualização indisponível</Typography>
        </Stack>
      )}

      <Stack
        {...utils.stack.flex.col('center', 'flex-start')}
        sx={{ width: '100%', flex: 1, overflowX: 'auto', overflowY: 'hidden' }}
      >
        <Pedigree bull={bull} />
      </Stack>

      {bull?.foot && bull.frame && bull.weight && bull.age && (
        <Box
          sx={{
            width: 'min-content',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100vw',
          }}
        >
          <ObjectView
            data={{
              Frame: bull.frame,
              Peso: bull.weight,
              CE: bull.foot,
              Idade: bull.age,
            }}
          />
        </Box>
      )}

      {bull.thoracicPerimeter && <Measurements bull={bull} />}
    </Stack>
  );
};

export default BullDetails;
